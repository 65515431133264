<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.payments.prepaid.topup') && financialDetails.payment_type_tag === 'prepaid'" class="payments">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('payments.Top_up_wallet_balance') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="paymentAvailable === false">
            <CRow class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
                <span v-html="$t('payments.Top_up_wallet_explainer')"></span>
              </CCol>
            </CRow>
            <CRow class="m-0 align-items-center justify-content-center prepaid_topup">
              <CCol cols="9" xl="9" lg="8" md="12" sm="12" class="pt-0 pb-0">
                <div>
                  <CRow class="m-0">
                    <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                      <b-slider size="is-medium" v-model="pointsToPurchase" :min="0" :max="20000" :step="250" rounded>
                        <div class="b-slider-tick null">
                          <span class="b-slider-tick-label">0</span>
                        </div>
                        <template v-for="val in 20000">                                      
                          <b-slider-tick v-if="(val % 2000 === 0)" :value="val" :key="val">{{ val ? val : 0 }}</b-slider-tick>
                        </template>
                      </b-slider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol v-for="(value, name, key) in [1000, 2500, 5000, 10000, 15000, 20000]" :key="key" cols="2" xl="2" lg="4" md="6" sm="6" class="pb-0">
                      <CButton class="w-100" color="primary" @click="pointsToPurchase = value;">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="currency mr-1">
                            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                          </div>
                          <div>
                            <span class="d-block">{{value}}</span>                                    
                          </div>
                        </div>
                      </CButton>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
              <CCol cols="3" xl="3" lg="4" md="12" sm="12" class="pt-0 pb-0">
                <div class="loyalty_points">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="currency mr-2">
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                    </div>
                    <div>
                      <span class="count d-block">{{pointsToPurchase}}</span>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="currency small mr-1">
                        <i class="fa-solid fa-euro-sign"></i>
                      </div>
                      <div>
                        <span class="count small d-block">{{formatToCurrency(pointsToPurchase)}}</span>
                      </div>
                    </div>
                    <div v-if="financialDetails.vat_percentage > 0" class="mt-1 d-flex align-items-center justify-content-center vat_notice">
                      <span><i class="fa-solid fa-euro-sign"></i>{{formatToCurrency(pointsToPurchase * financialDetails.vat_multiplier)}} {{$t('payments.including_vat', { vat_percentage : financialDetails.vat_percentage})}}</span>                                                                            
                    </div>
                  </div>
                  <div class="mt-2 text-center">
                    <CButton color="primary" @click="createMolliePrepaidPayment()" :disabled="pointsToPurchase === 0">
                      <span><i class="fas fa-square-up-right mr-1"/>{{$t('payments.Top_up_my_wallet_balance')}}</span>
                    </CButton>
                  </div>   
                </div>           
              </CCol>
            </CRow>
            <CRow v-if="errorMessage" class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                <span v-html="errorMessage" class="error"></span>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                <span v-html="$t('payments.Need_help')"></span>
              </CCol>              
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                <CButton color="secondary" @click="openContactCallSchedulePage()">
                  <span><i class="fa-solid fa-calendar-days mr-1"></i>{{$t('common.Schedule_contact_call')}}</span>
                </CButton>              
              </CCol>
            </CRow>
          </div>
          <div v-else>
            <CRow v-if="paymentEventTypeTag === 'top_up_company_wallet_balance' && paymentDetails" class="m-0">
              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                <CCard class="h-100 summary_card show_border mb-0">
                  <CCardHeader>
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="d-flex align-items-center justify-content-center header_icon">
                        <i class="fa-solid fa-basket-shopping"></i>
                      </div>
                      <h3 class="m-0 ml-2">{{$t('payments.Your_purchase')}}</h3>
                    </div>
                  </CCardHeader>
                  <CCardBody class="d-flex flex-column align-items-center justify-content-center pt-4 pb-4">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="currency mr-2">
                        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                      </div>
                      <span class="count d-block">{{paymentDetails.quantity}}</span>
                    </div>
                    <div class="mt-1 d-flex align-items-center justify-content-center">
                      <span>{{$t('common.Coins')}}</span> 
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                <CCard class="h-100 summary_card show_border mb-0">
                  <CCardHeader>
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="d-flex align-items-center justify-content-center header_icon">
                        <i class="fa-solid fa-file-invoice"></i>
                      </div>
                      <h3 class="m-0 ml-2">{{$t('payments.Total_costs')}}</h3>
                    </div>
                  </CCardHeader>
                  <CCardBody class="d-flex flex-column align-items-center justify-content-center pt-4 pb-4">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="currency mr-1">
                        <i class="fa-solid fa-euro-sign"></i>
                      </div>                      
                      <span class="count d-block">{{formatToCurrency(paymentDetails.quantity)}}</span>                  
                    </div>
                    <div v-if="paymentDetails.vat_percentage > 0" class="mt-1 d-flex align-items-center justify-content-center">
                      <span><i class="fa-solid fa-euro-sign"></i>{{formatToCurrency(paymentDetails.quantity * paymentDetails.vat_multiplier)}} {{$t('payments.including_vat', { vat_percentage : paymentDetails.vat_percentage})}}</span>                                                                            
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                <CCard class="h-100 summary_card show_border mb-0">
                  <CCardHeader>
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="d-flex align-items-center justify-content-center header_icon">
                        <i class="fa-solid fa-link"></i>
                      </div>
                      <h3 class="m-0 ml-2">{{$t('payments.Payment_link')}}</h3>
                    </div>
                  </CCardHeader>
                  <CCardBody class="d-flex flex-column align-items-center justify-content-center pt-4 pb-4">
                    <span v-html="$t('payments.Click_on_checkout_url_prepaid')"></span>
                    <div v-if="paymentCheckoutUrl" class="w-100 d-flex align-items-center mt-2">
                      <CRow class="m-0 payment_link_buttons">
                        <CCol cols="6" xl="6" lg="12" md="12" sm="12" class="p-0 pr-1_5">
                          <CButton color="primary" @click="openPaymentCheckoutUrl()" class="w-100 m-0">
                            <span><i class="fas fa-chevron-right mr-1"/>{{$t('payments.Pay_directly')}}</span>
                          </CButton>                    
                        </CCol>
                        <CCol cols="6" xl="6" lg="12" md="12" sm="12" class="p-0 pl-1_5">
                          <CButton color="secondary" @click="copyToClipboard()" class="w-100 m-0">
                            <span><i class="fas fa-copy mr-1"/>{{$t('payments.Copy_payment_link')}}</span>
                          </CButton>
                        </CCol>                        
                      </CRow>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                <span v-html="$t('payments.Need_help')"></span>
              </CCol>              
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                <CButton color="secondary" @click="openContactCallSchedulePage()">
                  <span><i class="fa-solid fa-calendar-days mr-1"></i>{{$t('common.Schedule_contact_call')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'TopUp',
  components: {
    noPermission
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      environmentHelpers: {
        environment_schedule_call_url: null
      },      
      platformPermissions: [],
      platformPermissionsLoaded: false,
      financialDetails: {
        payment_type_tag: null
      },
      pointsToPurchase: 0,
      paymentAvailable: false,
      paymentCheckoutUrl: null,
      paymentIdExternal: null,
      paymentEventTypeTag: null,
      paymentDetails: null,     
      errorMessage: null,      
    }
  },
  methods: {
    getCompanyFinancialDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails')
      .then(res => {      
        this.financialDetails = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });      
    },
    getMolliePrepaidPayment() {
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/payment/prepaid')
      .then(res => {
        let payment = res.data.data;
        // Update the paymentAvailable value
        this.paymentAvailable = payment.payment_available;
        // Update the paymentCheckoutUrl value
        if(payment.payment_checkout_url ) this.paymentCheckoutUrl = payment.payment_checkout_url;
        // Update the paymentIdExternal value
        if(payment.payment_id_external ) this.paymentIdExternal = payment.payment_id_external;
        // Update the paymentEventTypeTag value
        if(payment.payment_event_type_tag ) this.paymentEventTypeTag = payment.payment_event_type_tag;        
        // Update the paymentDetails value
        if(payment.payment_details ) this.paymentDetails = payment.payment_details;
      })
      .catch(err => {    
        console.error(err);
      });
    },
    createMolliePrepaidPayment() {
      // Set the params
      let params = {};
      params.points_to_purchase = this.pointsToPurchase;
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/payment/prepaid', params)
      .then(res => {
        let payment = res.data.data;
        // Update the paymentAvailable value
        this.paymentAvailable = true;
        // Update the paymentCheckoutUrl value
        this.paymentCheckoutUrl = payment.payment_checkout_url;
        // Update the paymentIdExternal value
        this.paymentIdExternal = payment.payment_id_external;
        // Update the paymentEventTypeTag value
        this.paymentEventTypeTag = payment.payment_event_type_tag;             
        // Update the paymentDetails value
        this.paymentDetails = payment.payment_details;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {    
        if(err.response.data.code === 'Payment Failed') {          
          this.errorMessage = this.$t('payments.Payment_failed');
        }
      });
    },
    cancelMolliePayment() {
      // Set the params
      let params = {};
      params.payment_id_external = this.paymentIdExternal;
      // Reset the error message
      this.errorMessage = null;
      // Cancel the Mollie payment
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/payment/cancel', params)
      .then(res => {        
        // Update the paymentAvailable value
        this.paymentAvailable = false;
        // Reset the paymentIdExternal value
        this.paymentIdExternal = null;
        // Reset the paymentCheckoutUrl value
        this.paymentCheckoutUrl = null;
      })
      .catch(err => {    
        console.error(err);
      });
    },
    openPaymentCheckoutUrl() {
      window.open(this.paymentCheckoutUrl, '_self');
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err);
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((points/10));
    },    
    openContactCallSchedulePage() {
      window.open(this.environmentHelpers.environment_schedule_call_url, '_blank');
    },
    copyToClipboard() {
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = this.paymentCheckoutUrl;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
      // Show message
      this.$buefy.toast.open({ message: this.$t('common.Copied_to_clipboard'), type: 'is-success', duration: 2000 });
    }       
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));

    this.getPlatformPermissions();
    this.getCompanyFinancialDetails();
    this.getMolliePrepaidPayment();    
  }
}
</script>
